<template>
  <div class="account-info-modal">
    <div class="container">
      <div class="title group">
        <img src="@/assets/icons/Group-yellow.svg" alt="">
        {{ account.groupName }}
      </div>
      <div class="title">
        <!-- <img src="@/assets/icons/Patrol.svg" alt=""> -->
        <img v-if="!accountName" :src="accountIcon" alt="" />
        <el-tooltip v-else popper-class="el-tooltip" effect="dark" v-delTabIndex :visible-arrow="false" :content="accountName" placement="left">
          <img :src="accountIcon" alt="" />
        </el-tooltip>
        {{ accountLabel }}
      </div>
      <div class="content-wrap">
        <div class="subject">{{ $t('name') }}</div>
        <div class="item">
          <div class="label">
            <img src="@/assets/icons/account-ab.svg" alt="">
            {{ $t('account_label')/*帳號名稱*/ }}
          </div>
          <div class="value">{{ account.name }}</div>
        </div>
        <div class="item" v-if="isDevice">
          <div class="label">
            <img src="@/assets/icons/movie.svg" alt="">
            {{ $t('account_video_title')/*影片標題*/ }}
          </div>
          <div class="value">{{ account.videoTitle }}</div>
        </div>
        <div class="subject">{{ $t('attribute') }}</div>
        <div class="item">
          <div class="label">
            <img src="@/assets/icons/public.svg" alt="">
            {{ $t('account_public_title')/*公開狀態*/ }}
          </div>
          <div class="value" v-if="account">
            {{
              account.public === '' ? '' : $t(`account_public_${account.public}`)
            }}
            <!-- <img v-if="account.public === 1" src="@/assets/icons/checked-green.svg" alt="">
            <img v-else src="@/assets/icons/Error.svg" alt=""> -->
          </div>
        </div>
        <div class="item">
          <div class="label">
            <img src="@/assets/icons/kind.svg" alt="">
            {{ $t('account_type')/*帳號類型*/ }}
          </div>
          <div class="value">{{ accountKind }}</div>
        </div>
        <template v-if="isUser">
          <div class="item">
            <div class="label">
              <img src="@/assets/icons/account_detail_role.svg" alt="" />
              {{ $t('account_role') /*角色*/ }}
            </div>
            <div class="value">{{ roleName /*TODO*/ }}</div>
          </div>
          <div class="item">
            <div class="label">
              <img src="@/assets/icons/account_detail_lang.svg" alt="" />
              {{ $t('account_lang') /*語系*/ }}
            </div>
            <div class="value">{{ language /*TODO*/ }}</div>
          </div>
          <div class="item">
            <div class="label">
              <img src="@/assets/icons/account_detail_tz.svg" alt="" />
              {{ $t('account_tz') /*時區*/ }}
            </div>
            <div class="value">{{ timezone /*TODO*/ }}</div>
          </div>
        </template>
        <template v-if="isDevice">
          <div class="subject">{{ $t('link') }}</div>
          <div class="item">
            <div class="label">
              <img src="@/assets/icons/kind.svg" alt="">
              {{ $t('account_linkmode_title')/*勾稽模式*/ }}
            </div>
            <div class="value">{{ linkedMode }}</div>
          </div>
          <div class="item">
            <div class="label">
              <img src="@/assets/icons/Group.svg" alt="">
              {{ $t('account_linkmode_group')/*群組*/ }}
            </div>
            <div class="value">
              <img
                v-if="account.linkedGroupName"
                src="@/assets/icons/Group-yellow.svg"
                alt=""
              />
              {{ account.linkedGroupName }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              <img src="@/assets/icons/user.svg" alt="">
              {{ $t('account_linkmode_user')/*使用者*/ }}
            </div>
            <div class="value">{{ linkedUser }}</div>
          </div>
        </template>
      </div>
      <div class="item foolter">
        <div class="btn" @click="closeModal">{{ $t('close') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { apiGetUserIndex, apiGetDeviceModels } from '@/api'
import { supportLangs } from '@/i18n/i18n.js'
import { mergeNameId } from '@/utils/'
import { euAccountKind } from '@/utils/global.js'
import { getDeviceModelIcon } from '@/config/account.js'
export default {
  name: 'AccountInfoModal',
  data() {
    return {
      euAccountKind,
      deviceModels: [],
      currAcc: null,
      account: {
        groupName: '',
        id: '',
        // 名稱
        name: '',
        videoTitle: '',
        // 屬性
        public: '',
        kind: 0,
        // roleId: '',
        // language: '',
        // timezone: '',
        // 勾稽
        linkedMode: 0,
        linkedGroupName: '',
        linkedUserName: '',
        linkedUserAccount: '',
      },
    }
  },
  computed: {
    ...mapState(['rightClickUser', 'groupList', 'userList']),
    // ...mapState('userinfo', ['deviceModels']),
    deviceKind() {
      let kind = ''
      switch (this.account.kind) {
        case 0:
          kind = this.$t('account_kind_default')/*預設*/
          break
        case 1:
          kind = this.$t('account_kind_sso')/*SSO(單簽)*/
          break
        case 2:
          kind = this.$t('account_kind_device')/*設備*/
          break
        case 3:
          kind = this.$t('account_kind_user')/*使用者*/
          break
        default:
          break
      }
      return kind
    },
    isDevice() {
      return (
        this.account.kind === euAccountKind.default ||
        this.account.kind === euAccountKind.device
      )
    },
    isUser() {
      return this.account.kind !== euAccountKind.device
    },
    accountName() {
      if (this.isDevice) {
        let deviceModelId = this.currAcc ? this.currAcc.deviceModelId : 0

        if (this.deviceModels?.length > 0) {
          const devModel = this.deviceModels.find(
            (model) => Number(model.id) === deviceModelId
          )
          return devModel ? devModel.name : null
        }
      }
      return null
    },
    accountLabel() {
      if (this.isDevice) {
        return mergeNameId(
          this.account.videoTitle || this.account.name,
          this.account.id
        )
      } else {
        // TODO
        return mergeNameId(this.account.name, this.account.id)
      }
    },
    accountIcon() {
      // 如果 account.kind === default, 先放設備 ICON
      if (this.isDevice) {
        let deviceModelId = this.currAcc ? this.currAcc.deviceModelId : 0
        const icon = getDeviceModelIcon(deviceModelId)
        return require(`@/assets/icons/device_model_${icon}.svg`)
      }
      return require(`@/assets/icons/user.svg`)
    },
    accountKind() {
      return this.$t(`account_kind_${this.account.kind}`)
    },
    roleName() {
      if (!this.currAcc) {
        return ''
      }

      return this.currAcc.roleName
    },
    language() {
      if (!this.currAcc) {
        return ''
      }

      const currLang = supportLangs.find(
        (lang) => lang.key === this.currAcc.language
      )
      return currLang?.str
    },
    timezone() {
      if (!this.currAcc) {
        return ''
      }

      return this.currAcc.timezone
    },
    linkedMode() {
      return this.$t(`account_linkmode_${this.account.linkedMode}`)
    },
    linkedUser() {
      let user = ''
      if (this.account.linkedUserName) {
        user = `${this.account.linkedUserName} ( ${this.account.linkedUserAccount})`
      }
      return user
    }
  },
  async mounted() {
    this.handleAccountInfo(this.rightClickUser)

    try {
      // Get User for language and timezone
      if (this.isUser && this.currAcc) {
        const res = await apiGetUserIndex(this.currAcc.index)

        if (res.status !== 200) {
          throw res.response
        }

        const user = res.data
        this.currAcc = { ...this.currAcc, ...user }
      }

      // Get DeviceModelList
      const rawRes = await apiGetDeviceModels()
      if (rawRes.status !== 200) throw rawRes
      this.deviceModels = rawRes.data
    } catch (err) {
      console.error(`[AccountInfoModal.mount] err:`, err)
    }
  },
  methods: {
    ...mapMutations(['updateShowAccountInfoModal', 'updateRightClickUser']),
    handleAccountInfo(accObj) {
      const group = this.groupList.find((group) => group.id === accObj.groupId)
      this.account = {
        groupName: group ? group.name : '',
        id: accObj.id,
        // 名稱
        name: accObj.info.name || '',
        videoTitle: accObj.video.title || '',
        deviceModelId: accObj.deviceModelId ? accObj.deviceModelId : 0,
        // 屬性
        public: accObj.public,
        kind: accObj.kind >= 0 ? accObj.kind : '',
        // 勾稽
        linkedMode: accObj.linkedMode >= 0 ? accObj.linkedMode : '',
        linkedGroupName: accObj.linkedGroupName || '',
        linkedUserName: accObj.linkedUserName || '',
        linkedUserAccount: accObj.linkedUserAccount || '',
      }

      this.currAcc = this.userList.find((user) => user.id === accObj.id)
    },
    closeModal() {
      this.updateShowAccountInfoModal(false)
      this.updateRightClickUser({})
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}
.account-info-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color_black_50;
  z-index: 99;

  .container {
    display: flex;
    flex-direction: column;
    border: 1px solid $color_4A5C78;
    // padding: px2rem(32) px2rem(68) px2rem(20) px2rem(32);
    padding: px2rem(32) px2rem(32) px2rem(20) px2rem(32);
    width: px2rem(660);
    max-height: 96%;
    color: $color_FFF;
    background: $color_282942;
    // overflow: overlay;
  }

  .title {
    display: flex;
    align-items: center;
    column-gap: px2rem(8);
    font-size: px2rem(24);
    line-height: px2rem(36);
    color: $color_FFF;
    margin-bottom: px2rem(8);
    &.group {
      color: $color_FFC600;
      font-weight: bold;
    }
    &:nth-child(2) {
      margin-bottom: px2rem(24);
    }

    img {
      @include img_icon_24x24;
    }
  }

  .content-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: overlay;
  }
  .subject {
    font-size: px2rem(20);
    line-height: px2rem(24);
    color: $color_FFE99F;
    margin-bottom: px2rem(12);
  }

  .item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    line-height: px2rem(36);
    margin-bottom: px2rem(12);
    &.foolter {
      margin-bottom: unset;
    }
    .label {
      width: 30%;
      height: px2rem(48);
      display: flex;
      align-items: center;
      column-gap: px2rem(8);
      padding: 0 px2rem(16);
      border: 1px solid $color_FFF_50;
      border-radius: px2rem(10) 0px 0px px2rem(10);
    }
    .value {
      width: 70%;
      height: px2rem(48);
      padding: 0 px2rem(20);
      display: flex;
      align-items: center;
      border: 1px solid $color_FFF_50;
      border-radius: 0 px2rem(10) px2rem(10) 0;
      border-left: none;
      background: $color_4A5C78_50;
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: px2rem(144);
      height: px2rem(52);
      color: $color_282942;
      background: $color_FFC600;
      border-radius: px2rem(8);
      margin-top: px2rem(20);
      cursor: pointer;
    }
  }
}
</style>
